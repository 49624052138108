import Glide from '@glidejs/glide';

export const stationGlide = () => {
  const d = document;
  const hasSlider = d.querySelector('.station__slide');

  if (!hasSlider) return;

  new Glide('.station__slide', {
    type: 'carousel',
    autoplay: 5000,
    animationDuration: 2000,
  }).mount();

  new Glide('.spaziopiu__slide', {
    type: 'carousel',
    autoplay: 5000,
    animationDuration: 2000,
  }).mount();
};
