import { generatePrice } from './generatePrice';
import { nav } from './nav';
import '../stylesheets/style.scss';
import { homeGlide } from './homeGlide';
import { stationDropdown } from './stationDropdown';
import { stationGlide } from './stationGlide';
import { aboutGlide } from './aboutGlide';
import { apremiatiGlide } from './apremiatiGlide';
import { businessGlide } from './businessGlide';

generatePrice();
nav();
stationDropdown();
homeGlide();
stationGlide();
aboutGlide();
apremiatiGlide();
businessGlide();
