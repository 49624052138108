import Glide from '@glidejs/glide';

export const businessGlide = () => {
  const d = document;
  const hasSlider = d.querySelector('.slide__business');

  if (!hasSlider) return;

  new Glide('.slide__business', {
    type: 'carousel',
    autoplay: 5000,
    animationDuration: 2000,
  }).mount();
};
