import Glide from '@glidejs/glide';

export const aboutGlide = () => {
  const d = document;
  const hasSlider =
    d.querySelector('.slide-about') && d.querySelector('.slide__azienda');

  if (!hasSlider) return;

  new Glide('.slide-about', {
    type: 'carousel',
    autoplay: 5000,
    animationDuration: 2000,
  }).mount();

  new Glide('.slide__azienda', {
    type: 'carousel',
    autoplay: 5000,
    animationDuration: 2000,
  }).mount();
};
