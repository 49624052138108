import Glide from '@glidejs/glide';

export const homeGlide = () => {
  const d = document;
  const hasSlider =
    d.querySelector('.glide') &&
    d.querySelector('.slider') &&
    d.querySelector('.slide-home');

  if (!hasSlider) return;

  new Glide('.glide', {
    autoplay: 5000,
    type: 'carousel',
    animationDuration: 2000,
  }).mount();

  new Glide('.slider', {
    type: 'carousel',
    animationDuration: 850,
    startAt: 0,
    perView: 3,
    gap: 0,

    // focusAt: 0,
    // breakpoints: {
    //   1024: { perView: 1, peek: 0 },
    //   1100: { gap: -169 },
    //   1200: { gap: -215 },
    //   1300: { gap: -261 },
    //   3000: {
    //     gap: 30,
    //     perView: 4,
    //     type: 'carousel',
    //   },
    // },
    breakpoints: {
      1024: { perView: 1 },
    },
  }).mount();

  new Glide('.slide-home', {
    autoplay: 5000,
    type: 'carousel',
    animationDuration: 2000,
  }).mount();
};
