export const nav = () => {
  const d = document;
  const openHamburger = d.querySelector('#js-open-hamburger-menu');
  const openNavHamburger = d.querySelector('#js-link-hamburger');
  const hamburger = d.querySelector('#js-hamburger-menu');
  const bodyFixed = d.querySelector('#js-pos-fixed');
  const line1 = d.querySelector('#line1');
  const line2 = d.querySelector('#line2');
  const line3 = d.querySelector('#line3');
  const navbarHamburgerDisplay = d.querySelector('#js-navbar-hamburger');
  const hamburgerDisplay = d.querySelector('#js-hamburger-list');
  const prova = d.querySelector('.navbar__link-hamburger');
  const navbar = d.querySelector('.navbar');
  const linkNav = d.querySelectorAll('.navbar__link--white');
  const navbarChevronDown = d.querySelector('.navbar__chevron');
  const logoNav = d.querySelector('.navbar__img');
  const hamburgerIcon = d.querySelector('.hamburger__icon');
  const buttonNav = d.querySelector('.button--navButton');
  const hamburgerLinkDropdown = d.querySelector('#js-hamburger-dropdown');

  const hamburgerMenu = () => {
    hamburger.classList.toggle('hamburger--active');
    bodyFixed.classList.toggle('hamburger--pos-fixed');
    line1.classList.toggle('hamburger__line1--selected');
    line2.classList.toggle('hamburger__line2--selected');
    line3.classList.toggle('hamburger__line3--selected');
    navbar.classList.toggle('navbar__scroll--bg-black');
  };

  document.addEventListener('click', function (event) {
    const isClickInsideElement = openNavHamburger.contains(event.target);
    if (
      !isClickInsideElement &&
      navbarHamburgerDisplay.classList.contains(
        'navbar__link-hamburger-container-active'
      )
    ) {
      navbarHamburgerDisplay.classList.remove(
        'navbar__link-hamburger-container-active'
      );
    }
  });

  const navbarHamburger = () => {
    navbarHamburgerDisplay.classList.toggle(
      'navbar__link-hamburger-container-active'
    );
  };

  document.addEventListener('click', function (event) {
    const isClickInsideElement = hamburgerDisplay.contains(event.target);
    if (
      !isClickInsideElement &&
      hamburgerLinkDropdown.classList.contains(
        'navbar__link-hamburger-container-active'
      )
    ) {
      hamburgerLinkDropdown.classList.remove(
        'navbar__link-hamburger-container-active'
      );
    }
  });

  const hamburgerDropdown = () => {
    hamburgerLinkDropdown.classList.toggle(
      'navbar__link-hamburger-container-active'
    );
  };

  openHamburger.addEventListener('click', hamburgerMenu);
  openNavHamburger.addEventListener('mouseenter', function () {
    navbarHamburgerDisplay.classList.add(
      'navbar__link-hamburger-container-active'
    );
  });
  navbarHamburgerDisplay.addEventListener('mouseleave', function () {
    setTimeout(() => {
      navbarHamburgerDisplay.classList.remove(
        'navbar__link-hamburger-container-active'
      );
    }, 500);
  });
  openNavHamburger.addEventListener('click', navbarHamburger);
  hamburgerDisplay.addEventListener('click', hamburgerDropdown);

  window.onscroll = function () {
    if (
      document.body.scrollTop >= 20 ||
      document.documentElement.scrollTop >= 20
    ) {
      navbar.classList.add('navbar__scroll');
      linkNav.forEach((e) => {
        e.classList.remove('navbar__link--white');
        e.classList.add('navbar__link--green');
      });
      navbarChevronDown.style.stroke = '#28623E';
      hamburgerIcon.style.fill = '#28623E';
      logoNav.src = 'src/assets/logo-green.png';
      buttonNav.classList.remove(
        'button--border-yellow',
        'button--yellow',
        'button--bg-transparent'
      );
      buttonNav.classList.add('button--white', 'button--bg-green');
    } else {
      navbar.classList.remove('navbar__scroll');
      linkNav.forEach((e) => {
        e.classList.remove('navbar__link--green');
        e.classList.add('navbar__link--white');
      });
      navbarChevronDown.style.stroke = '#fff';
      logoNav.src = 'src/assets/logo-white.png';
      hamburgerIcon.style.fill = '#fff';
      buttonNav.classList.remove('button--white', 'button--bg-green');
      buttonNav.classList.add(
        'button--border-yellow',
        'button--yellow',
        'button--bg-transparent'
      );
    }
  };
};
