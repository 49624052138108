import { getAllData } from './getSheetData';

export const generatePrice = () => {
  const boxPrice = document.querySelector('#js-box-prezzi');

  const generateItems = (array) => {
    let str = ``;
    array.forEach((item) => {
      str += `
            <div class="box-prezzi__card">
                <h2 class="box-prezzi__title">${item.carburante}</h2>
                <p class="box-prezzi__counter">€ ${item.prezzo}</p>
                <p class="box-prezzi__text">Euro/litro</p>
            </div>
          `;
    });
    return str;
  };

  getAllData().then((res) => {
    res.forEach((element) => {
      if (!boxPrice) return;

      const { title, items } = element;
      boxPrice.innerHTML += `
           ${generateItems(items)}
        `;
    });
  });
};
