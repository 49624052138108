import Glide from '@glidejs/glide';

export const apremiatiGlide = () => {
  const d = document;
  const hasSlider = d.querySelector('.slide__apremiati');

  if (!hasSlider) return;

  new Glide('.slide__apremiati', {
    //type: 'carousel',
    //autoplay: 5000,
    //animationDuration: 2000,
  }).mount();
};
