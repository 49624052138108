export const stationDropdown = () => {
  const stationDropdown = document.querySelector('#js-station-dropdown');
  const stationDropdownTitle = document.querySelector(
    '#js-station-dropdown-title'
  );
  const stationDropdownList = document.querySelector(
    '#js-station-dropdown-list'
  );
  // const prova = document.querySelector('.underline');

  if (!stationDropdown) return;

  const stationDropdownShow = () => {
    stationDropdownTitle.classList.toggle('station-dropdown__title--active');
    stationDropdownList.classList.toggle('station-dropdown__list--active');
    // prova.style.width = '100%';
  };

  stationDropdownTitle.addEventListener('click', stationDropdownShow);

  document.addEventListener('click', function (event) {
    const isClickInsideElement = stationDropdownTitle.contains(event.target);
    if (
      !isClickInsideElement &&
      stationDropdownList.classList.contains('station-dropdown__list--active')
    ) {
      stationDropdownTitle.classList.remove('station-dropdown__title--active');
      stationDropdownList.classList.remove('station-dropdown__list--active');
      // prova.style.maxwidth = '0%';
      // prova.style.transition = 'width 2s, max-width 2s';
    }
  });
};
